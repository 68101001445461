"use client";

import { ReactNode, useMemo } from "react";
import { DialogCancel, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogFooter, Button, Dialog } from "@openchatai/hoose/ui";
import { Store, useStore } from "@/lib/store";
import _ from "lodash";
import { createSafeContext } from "@/lib/createSafeContext";
type DialogType = {
  id: string;
  open: boolean;
  title: string;
  description?: string;
  content?: ReactNode | ((dialoger: DialogStore, dialog: DialogType) => ReactNode);
  body?: ReactNode; // replaces the whole content
  cancelText?: string;
  confirmText?: string;
  onConfirm?: (dialoger: DialogStore, dialog: DialogType) => void;
  onCancel?: (dialoger: DialogStore, dialog: DialogType) => void;
  footer?: boolean;
  dialoger: DialogStore;
};
type DialogOptions = {
  onClose?: (id: string) => void;
};

// this is the
class DialogStore extends Store<{
  dialogs: DialogType[];
}, DialogOptions> {
  closeDialog = (id: string) => {
    this.setValue(state => {
      const dialogs = state.dialogs.filter(dialog => dialog.id !== id);
      return {
        dialogs
      };
    });
  };
  private _openDialog = (dialog: DialogType) => {
    this.setValue(state => {
      const dialogs = [...state.dialogs, dialog];
      return {
        dialogs
      };
    });
  };
  openDialog = ({
    footer = true,
    ...dialog
  }: Omit<DialogType, "id" | "open" | "dialoger">) => {
    const id = _.uniqueId();
    this._openDialog({
      id,
      ...dialog,
      open: true,
      footer,
      dialoger: this
    });
  };
}
const [DialogerInstanceSafeProvider, useDialoger] = createSafeContext<{
  dialoger: DialogStore;
}>("Dialoger instance");
export function DialogerProvider({
  children
}: {
  children: ReactNode;
}) {
  const dialoger = useMemo(() => new DialogStore({
    dialogs: []
  }), []);
  return <DialogerInstanceSafeProvider value={{
    dialoger
  }} data-sentry-element="DialogerInstanceSafeProvider" data-sentry-component="DialogerProvider" data-sentry-source-file="Dialoger.tsx">
      {children}
    </DialogerInstanceSafeProvider>;
}
export { useDialoger };
export function Dialoger() {
  const {
    dialoger
  } = useDialoger();
  const {
    dialogs
  } = useStore(dialoger);
  return <>
      {dialogs.map(dialog => <Dialog key={dialog.id} open={dialog.open} onOpenChange={open => {
      if (dialog.onConfirm || dialog.onCancel) {
        return;
      }
      if (open) {
        dialoger.openDialog(dialog);
      } else {
        dialoger.closeDialog(dialog.id);
      }
    }}>
          <DialogContent forceMount>
            {dialog.body ? dialog.body : <>
                <DialogHeader>
                  <DialogTitle className="text-start">
                    {dialog.title}
                  </DialogTitle>
                  <DialogDescription>{dialog.description}</DialogDescription>
                </DialogHeader>
                <div className="w-full">
                  {typeof dialog.content === "function" ? dialog.content(dialoger, dialog) : dialog.content}
                </div>
                {dialog.footer && <DialogFooter className="flex items-center gap-2 justify-end">
                    <DialogCancel onClick={() => {
              if (dialog.onCancel) {
                dialog.onCancel(dialoger, dialog);
              }
            }}>
                      {dialog.cancelText ?? "Cancel"}
                    </DialogCancel>
                    <Button onClick={() => {
              if (dialog.onConfirm) {
                dialog.onConfirm(dialoger, dialog);
              }
            }}>
                      {dialog.confirmText ?? "OK"}
                    </Button>
                  </DialogFooter>}
              </>}
          </DialogContent>
        </Dialog>)}
    </>;
}