"use client";
// https://github.com/openchatai/superpowers/blob/main/packages/little-store/src/StoreImpl.ts
import { produce } from "immer";
import { useSyncExternalStore } from "react";

type UpdaterFunction<T> = (oldValue: T) => T;

type Listener<T> = (value: T) => void;

export abstract class Store<T extends any = any, S extends unknown = unknown> {
  protected value: T;
  protected options: S | undefined;
  storeListeners = new Set<Listener<T>>();
  keyListeners = new Map<keyof T, Set<Listener<T[keyof T]>>>();

  constructor(initialValue: T, options?: S) {
    this.value = initialValue;
    if (options) {
      this.options = options;
    }
  }

  setValue = (newValue: T | UpdaterFunction<T>) => {
    if (typeof newValue === "function") {
      this.value = (newValue as UpdaterFunction<T>)(this.value as T);
    } else {
      this.value = newValue;
    }
    this.notify();
  };

  setValueImmer = (updater: (draft: T) => void) => {
    this.setValue(produce(this.value, updater));
  };

  getValue = () => {
    return this.value;
  };

  unSubscribe = (listener: Listener<T>) => {
    this.storeListeners.delete(listener);
  };

  subscribe = (listener: Listener<T>) => {
    this.storeListeners.add(listener);
    return () => {
      this.unSubscribe(listener);
    };
  };

  addEventListener = this.subscribe;

  addKeyEventListener = <K extends keyof T>(
    key: K,
    listener: Listener<T[K]>,
  ) => {
    if (!this.keyListeners.has(key)) {
      this.keyListeners.set(key, new Set());
    }
    this.keyListeners.get(key)!.add(listener as Listener<T[keyof T]>);
    return () => {
      this.keyListeners.get(key)!.delete(listener as Listener<T[keyof T]>);
      if (this.keyListeners.get(key)!.size === 0) {
        this.keyListeners.delete(key);
      }
    };
  };

  notify = () => {
    this.storeListeners.forEach((l) => l(this.value as T));
  };
}

// export function useStore<T>(
//   store: Store<T>,
//   // typeof the store.key selected
//   selector?: <K extends keyof T, S extends T[K]>(state: T) => S
// ) {
//   const cb = selector ? () => selector(store.getValue()) : store.getValue;

//   return useSyncExternalStore(store.subscribe, cb, cb);
// }

export function useStore<T = unknown>(store: Store<T>) {
  return useSyncExternalStore(store.subscribe, store.getValue, store.getValue);
}
