import(/* webpackMode: "eager", webpackExports: ["AppConfigProvider"] */ "/app/apps/dashboard/app/_data-providers/AppConfigProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CallProvider"] */ "/app/apps/dashboard/app/_data-providers/CallProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DialogerProvider","Dialoger"] */ "/app/apps/dashboard/app/_data-providers/Dialoger.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/app/apps/dashboard/app/[locale]/session-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SWRProvider"] */ "/app/apps/dashboard/app/[locale]/swr-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TopLoader"] */ "/app/apps/dashboard/lib/Toploader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/packages/hoose/dist/headless.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/packages/hoose/dist/ui.js");
