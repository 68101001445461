"use client";

import { SessionProvider as NextSessionProvider } from "next-auth/react";
import React from "react";
export function SessionProvider({
  children
}: {
  children: React.ReactNode;
}) {
  return <NextSessionProvider data-sentry-element="NextSessionProvider" data-sentry-component="SessionProvider" data-sentry-source-file="session-provider.tsx">{children}</NextSessionProvider>;
}