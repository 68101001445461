"use client";

import React from "react";
import { SWRConfig } from "swr";
export function SWRProvider({
  children
}: {
  children: React.ReactNode;
}) {
  return <SWRConfig value={{
    revalidateOnFocus: false
  }} data-sentry-element="SWRConfig" data-sentry-component="SWRProvider" data-sentry-source-file="swr-provider.tsx">
      {children}
    </SWRConfig>;
}