"use client";

import { IS_SERVER } from "@/lib/env";
import { createSafeContext } from "@openchatai/hoose/utils";
import _, { cloneDeep, set as lodashSet } from "lodash";
import React, { useEffect, useState } from "react";
import { useIsomorphicLayoutEffect } from "react-use";
const [SafeAppConfigProvider, useAppConfig] = createSafeContext<{
  state: Record<string, any>;
  set: <T extends any = unknown>(path: string, value: T) => void;
  get: <T extends any = unknown>(path: string) => T;
}>("");
type StorageType = "local" | "session";
const STORAGE_TYPE: StorageType = "local";
const KEY = "app-config";
const SYNC_TO_LOCAL_STORAGE = true;
function getInitialData(storageType: StorageType, key: string) {
  if (IS_SERVER) return {};
  const storage = storageType === "local" ? localStorage : sessionStorage;
  const data = storage.getItem(key);
  return data ? JSON.parse(data) : {};
}
function AppConfigProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const [state, setState] = useState<Record<string, any>>({});
  useIsomorphicLayoutEffect(() => {
    if (IS_SERVER) return;
    let initial = getInitialData(STORAGE_TYPE, KEY);
    if (initial) {
      setState(initial);
    }
  }, []);
  const set = React.useCallback((path: string, value: any) => {
    setState(prev => {
      const newState = cloneDeep(prev);
      lodashSet(newState, path, value);
      if (SYNC_TO_LOCAL_STORAGE) {
        const storage = STORAGE_TYPE === "local" ? localStorage : sessionStorage;
        storage.setItem(KEY, JSON.stringify(newState));
      }
      return newState;
    });
  }, [setState]);
  const get = React.useCallback((path: string) => _.get(state, path), [state]);
  return <SafeAppConfigProvider value={{
    get,
    set,
    state
  }} data-sentry-element="SafeAppConfigProvider" data-sentry-component="AppConfigProvider" data-sentry-source-file="AppConfigProvider.tsx">
      {children}
    </SafeAppConfigProvider>;
}

// TODO use this as the type of `path` for `useConfigState`
enum AppConfigPathEnum {
  "animation.copilot-magic-orb" = "animation.copilot-magic-orb",
  "animation.copilot-magic-orb-pulse" = "animation.copilot-magic-orb-pulse",
  "appearance.color-mode" = "appearance.color-mode",
}
function useConfigState<T extends any = unknown>(path: string, initial?: T): [T | undefined, (value: T) => void] {
  const {
    set,
    get
  } = useAppConfig();
  const value = get<T>(path) ?? initial;
  const setValue = (value: T) => set(path, value);
  return [value, setValue] as const;
}
export { useAppConfig, AppConfigProvider, useConfigState, AppConfigPathEnum };